import { Button, Divider, Modal } from 'antd';
import React, { useState } from 'react';
import { TaskObj } from '../../../store/ducks/tasks';
import TaskShare from './TaskShare';
import './TodoTaskOptionsPanel.scss';

/** interface to describe TodoTaskOptionsPanel props */
interface TodoTaskOptionsPanelProps {
  editRequestHandler: () => void;
  deleteRequestHandler: () => void;
  taskInfo: TaskObj;
  panelCloseHandler: () => void;
  isModalVisible?: any;
  setIsModalVisible?: any;
}

const TodoTaskOptionsPanel: React.FC<TodoTaskOptionsPanelProps> = (
  props: TodoTaskOptionsPanelProps
) => {
  const {
    deleteRequestHandler,
    editRequestHandler,
    panelCloseHandler,
    taskInfo,
    isModalVisible,
    setIsModalVisible,
  } = props;

  const { confirm } = Modal;

  // const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const config = {
    className: 'TodoTaskOptionsPanel-modal-container',
    title: <div className="TodoTaskOptionsPanel-modal-title">Delete Task</div>,
    content: (
      <div>
        Are you sure you want to delete the task{' '}
        <span style={{ color: 'var(--theme-primary)' }}> {taskInfo.title}</span>{' '}
        ?<br />
      </div>
    ),
    width: 500,
    onOk() {
      deleteRequestHandler();
    },
  };

  /** closes the panel and calls the delete request */
  const editRequest = () => {
    panelCloseHandler();
    editRequestHandler();
  };

  /** closes the panel and confirms the delete */
  const deleteRequest = () => {
    panelCloseHandler();
    confirm(config);
  };

  const showShareModal = () => {
    //
    panelCloseHandler();
    setIsModalVisible(true);
  };
  return (
    <div className="TodoTaskOptionsPanel-container">
      <Button
        icon={<i className="fas fa-user-edit"></i>}
        className="TodoTaskOptionsPanel-item"
        onClick={editRequest}
      >
        Edit Task
      </Button>
      <Divider type="horizontal" />
      {taskInfo.projectId == '' ? (
        <>
          <Button
            icon={<i className="fas fa-share-alt"></i>}
            className="TodoTaskOptionsPanel-item"
            onClick={showShareModal}
          >
            Share Task
          </Button>
          <Divider type="horizontal" />
        </>
      ) : null}

      <Button
        icon={<i className="fas fa-trash"></i>}
        className="TodoTaskOptionsPanel-item"
        onClick={deleteRequest}
      >
        Delete Task
      </Button>
    </div>
  );
};

/** allow default import */
export default TodoTaskOptionsPanel;
