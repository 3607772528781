import React from 'react';
import './DetailedTasksList.scss';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import TaskItem from '../taskItem/TaskItem';
import { connect } from 'react-redux';
import {
  getProjectAndSectionBasedTasks,
  TaskObj,
  updateTasks,
} from '../../store/ducks/tasks';
import { Store } from 'redux';
import {
  getFlatDataFromTree,
  getTreeDataFromFlat,
} from '../../services/treeConverter';
import lodash from 'lodash';
import { syncService } from '../../services/syncService';
import FetchCompletedTasks from '../fetchCompletedTasks/FetchCompletedTasks';

interface ExtendedTaskObj extends TaskObj {
  actualParent: string;
}

interface DetailedTasksListProps {
  taskId: string;
  projectId: string;
  sectionId: string;
  treeData: any;
  completedTreeData: any;
  setTreeTasksActionCreator: typeof updateTasks;
}

const DetailedTasksList: React.FC<DetailedTasksListProps> = (
  props: DetailedTasksListProps
) => {
  const {
    treeData,
    setTreeTasksActionCreator,
    projectId,
    sectionId,
    completedTreeData,
    taskId,
  } = props;

  const treeChangeHandler = (modifiedTreeData: any) => {
    setTreeTasksActionCreator(
      getFlatDataFromTree(modifiedTreeData as any).map(
        (iterTaskObj: TaskObj) => ({
          ...iterTaskObj,
          projectId: projectId,
          sectionId: sectionId,
          parent: taskId,
        })
      ) as any
    );
    syncService();
  };

  return (
    <div className="DetailedTasksList-container">
      {treeData.length > 0 ? (
        <SortableTree
          dndType="task"
          isVirtualized={false}
          treeData={treeData}
          generateNodeProps={({ node, path }) => ({
            title: (
              <TaskItem
                preventExpanded={true}
                path={path.length}
                task={{ ...node, parent: node.actualParent } as any}
              />
            ),
          })}
          maxDepth={1}
          onChange={treeChangeHandler}
        />
      ) : (
        <div className="DetailedTasksList-container-empty">
          There is no pending sub tasks.
        </div>
      )}
      {completedTreeData.length > 0 && (
        <React.Fragment>
          <div>
            <div className="DetailedTasksList-completed-title">Completed</div>
          </div>
          <div className="DetailedTasksList-completed-section">
            <SortableTree
              dndType="completedTask"
              isVirtualized={false}
              treeData={completedTreeData}
              generateNodeProps={({ node, path }) => ({
                title: (
                  <TaskItem
                    preventExpanded={true}
                    path={path.length}
                    task={{ ...node, parent: node.actualParent } as any}
                  />
                ),
              })}
              onChange={treeChangeHandler}
              maxDepth={1}
            />
          </div>
        </React.Fragment>
      )}
      <div>
        <FetchCompletedTasks
          parentTaskId={taskId}
          isPresent={completedTreeData.length > 0}
          projectId={projectId}
          sectionId={sectionId}
        />
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  treeData: any;
  completedTreeData: any;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  ParentProps: any
): DispatchedStateProps => {
  const tmpFlatData = getProjectAndSectionBasedTasks(
    state,
    ParentProps.projectId,
    ParentProps.sectionId
  );

  const result = {
    treeData: getTreeDataFromFlat(
      lodash.map(
        lodash.filter(
          tmpFlatData,
          (iterTask: TaskObj) =>
            iterTask.parent === ParentProps.taskId &&
            !iterTask.completed &&
            !iterTask.deleted
        ),
        (iterTask: ExtendedTaskObj) => ({
          ...iterTask,
          actualParent: iterTask.parent,
          parent: '',
        })
      )
    ),
    completedTreeData: getTreeDataFromFlat(
      lodash.map(
        lodash.filter(
          tmpFlatData,
          (iterTask: TaskObj) =>
            iterTask.parent === ParentProps.taskId && iterTask.completed
        ),
        (iterTask: ExtendedTaskObj) => ({
          ...iterTask,
          actualParent: iterTask.parent,
          parent: '',
        })
      )
    ),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setTreeTasksActionCreator: updateTasks,
};

/** connect DetailedTasksList to the redux store */
const ConnectedDetailedTasksList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedTasksList);

export default ConnectedDetailedTasksList;
