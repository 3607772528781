import React from 'react';
import './App.scss';
import { Switch, Route, Redirect } from 'react-router-dom';
import Todo from '../components/todo/todo/Todo';
import PrivateRoute from '../containers/privateRoute/PrivateRoute';
import TodoProject from '../components/todo/todoProject/TodoProject';
import { axioService, GET } from '../services/axioService';
import { ProjectObj, setProjects } from '../store/ducks/projects';
import { getSessionToken, setSessionUserInfo } from '../store/ducks/session';
import { Store } from 'redux';
import { connect } from 'react-redux';
import TodoLabelPage from '../components/todo/todoLabelPage/TodoLabelPage';
import {
  ServerLabelObj,
  ServerProjectObj,
  ServerSectionObj,
  syncService,
} from '../services/syncService';
import { LabelObj, setLabels } from '../store/ducks/labels';
import {
  LOCAL_AUTHORIZE_ENDPOINT,
  LOCAL_GENERAL_SETTINGS_ENDPOINT,
  LOCAL_INBOX_ENDPOINT,
  LOCAL_INVITATION_ENDPOINT,
  LOCAL_LABEL_ENDPOINT,
  LOCAL_LOGOUT_ENDPOINT,
  LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT,
  LOCAL_PROJECT_ENDPOINT,
  LOCAL_SEARCH_ENDPOINT,
  LOCAL_TODAY_ENDPOINT,
  LOCAL_UPCOMING_ENDPOINT,
  SERVER_COLLABORATORS_ENDPOINT,
  SERVER_LABELS_ENDPOINT,
  SERVER_MEETING_DASHBOARD_COUNT_ENDPOINT,
  SERVER_PROJECTS_ENDPOINT,
  SERVER_SECTIONS_ENDPOINT,
  SERVER_SETTINGS_ENDPOINT,
  SERVER_TASKS_ENDPOINT,
  SERVER_USER_PROFILE_ENDPOINT,
  LOCAL_SHARED_TASKS_ENDPOINT,
} from '../configs/endpoints';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ConnectedSessionAuthorize from '../containers/sessionAuthorize/SessionAuthorize';
import ConnectedSessionDestroy from '../containers/sessionDestroy/SessionDestroy';
import GeneralSettings from '../containers/generalSettings/GeneralSettings';
import TodoTodayPage from '../components/todo/todoTodayPage/TodoTodayPage';
import TodoSearchPage from '../components/todo/todoSearchPage/TodoSearchPage';
import { SectionObj, setSections } from '../store/ducks/sections';
import { setTasks, TaskObj } from '../store/ducks/tasks';
import TodoUpcomingPage from '../components/todo/todoUpcomingPage/TodoUpcomingPage';
import { setNotifications } from '../store/ducks/notifications';
import { setSettings } from '../store/ducks/settings';
import lodash from 'lodash';
import {
  CollaboratorObj,
  setCollaborators,
} from '../store/ducks/collaborators';
import ConnectedInvitation from '../containers/invitation/Invitation';
import Meeting from '../containers/meeting/Meeting';
import { MEETING_ACTIVTY_TYPE } from '../configs/activityTypes';
import { setMeetingInfo } from '../store/ducks/meeting';
import { fetchNotificationFromServer } from '../api/notifications/request';
import { transformTaskFromServer } from '../api/tasks/transformer';
import { ServerTaskObj } from '../api/tasks/data';
import SharedTaskPage from '../components/todo/sharedTaskPage/SharedTaskPage';

interface AppProps {
  token: string;
  setProjectsActionCreator: typeof setProjects;
  setLabelsActionCreator: typeof setLabels;
  setSectionsActionCreator: typeof setSections;
  setTasksActionCreator: typeof setTasks;
  setSessionUserInfoActionCreator: typeof setSessionUserInfo;
  setNotificationsActionCreator: typeof setNotifications;
  setSettingsActionCreator: typeof setSettings;
  setCollaboratorsActionCreator: typeof setCollaborators;
  setMeetingInfoActionCreator: typeof setMeetingInfo;
}

/** the sync time interval in minutes */
const SYNC_TIME_INTERVAL = 1;

const App: React.FC<AppProps> = (props: AppProps) => {
  const {
    token,
    setSessionUserInfoActionCreator,
    setProjectsActionCreator,
    setLabelsActionCreator,
    setSectionsActionCreator,
    setTasksActionCreator,
    setNotificationsActionCreator,
    setSettingsActionCreator,
    setCollaboratorsActionCreator,
    setMeetingInfoActionCreator,
  } = props;

  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    // sets the theme colors
    document.body.style.setProperty('--theme-dark', '#2777F4');
    document.body.style.setProperty('--theme-primary', '#3161f1');
    document.body.style.setProperty('--theme-light', '#1897F8');
    document.body.style.setProperty('--theme-hover-light', '#dde4fc');

    /** run the sync service at an interval */
    const interval = setInterval(() => {
      /** TODO: comment next line to stop sync process or vice versa */
      syncService();
    }, SYNC_TIME_INTERVAL * 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  // sets the initial data
  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_USER_PROFILE_ENDPOINT,
          {},
          true
        );
        setSessionUserInfoActionCreator({
          ...response.data.data,
          id: response.data.data.id.toString(),
        });
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_PROJECTS_ENDPOINT,
          {},
          true
        );

        /** also can be called connected users */
        let interestedUsers: string[] = [];

        setProjectsActionCreator(
          response.data.data.map(
            (iterProj: ServerProjectObj): ProjectObj => {
              /** append the unique users */
              interestedUsers = lodash.uniq(
                interestedUsers.concat(iterProj.shared_with || [])
              );

              return {
                id: iterProj.id,
                title: iterProj.title,
                color: iterProj.color,
                parent: iterProj.parent_id ? iterProj.parent_id : '',
                expanded: iterProj.is_expanded ? true : false,
                isFavourite: iterProj.is_favorite ? true : false,
                isHidden: false,
                order: iterProj.order,
                synced: true,
                deleted: false,
                /** converting to string */
                sharedWith: iterProj.shared_with?.map(String) || [],
              };
            }
          )
        );

        /** get the connected users or collaborators information */
        const collaboratorsResponse = await axioService(
          GET,
          SERVER_COLLABORATORS_ENDPOINT,
          {
            ids: interestedUsers,
            limit: 100,
          },
          true
        );

        /** set the collaborators to store */
        setCollaboratorsActionCreator(
          collaboratorsResponse.data.data.map(
            (iterCollaborator: any): CollaboratorObj => ({
              id: iterCollaborator?.id?.toString() || '',
              name: iterCollaborator.name || '',
              email: iterCollaborator.email || '',
              mobile: iterCollaborator.mobile || '',
              avatar: iterCollaborator.avatar || '',
            })
          )
        );
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    const fetchLabels = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_LABELS_ENDPOINT,
          {},
          true
        );
        setLabelsActionCreator(
          response.data.data.map(
            (iterLabel: ServerLabelObj): LabelObj => ({
              id: iterLabel.id,
              title: iterLabel.title,
              color: iterLabel.color,
              parent: '',
              expanded: true,
              isFavourite: iterLabel.is_favorite ? true : false,
              isHidden: false,
              order: iterLabel.order,
              synced: true,
              deleted: false,
            })
          )
        );
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    const fetchSections = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_SECTIONS_ENDPOINT,
          {},
          true
        );
        setSectionsActionCreator(
          response.data.data.map(
            (iterSection: ServerSectionObj): SectionObj => ({
              id: iterSection.id,
              title: iterSection.title,
              parent: '',
              expanded: iterSection.is_expanded ? true : false,
              bodyExpanded: iterSection.is_expanded ? true : false,
              projectId: iterSection.project_id ? iterSection.project_id : '',
              order: iterSection.order,
              synced: true,
              deleted: false,
            })
          )
        );
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    const fetchTasks = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_TASKS_ENDPOINT + '?per_page=5000',
          {},
          true
        );
        setTasksActionCreator(
          response.data.data.map(
            (iterTask: ServerTaskObj): TaskObj =>
              transformTaskFromServer(iterTask)
          )
        );
        setLoading(false);
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    const fetchNotifications = async () => {
      try {
        const notifications = await fetchNotificationFromServer();
        setNotificationsActionCreator(notifications);
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    const fetchSettings = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_SETTINGS_ENDPOINT,
          {},
          true
        );
        setSettingsActionCreator({
          dateFormat: response.data.general.date_format,
          timeFormat: response.data.general.time_format,
          timezone: response.data.general.timezone,
          nextWeek: response.data.todo.next_week,
          defaultCompletionTime: response.data.todo.completion_time,
        });
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    const fetchMeetingCount = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_MEETING_DASHBOARD_COUNT_ENDPOINT,
          {},
          true
        );
        setMeetingInfoActionCreator({
          total: response.data.total,
          upcoming: response.data.upcoming,
          cancel: response.data.cancel,
        });
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };

    if (token !== '') {
      setLoading(true);
      fetchProfile();
      fetchProjects();
      fetchLabels();
      fetchSections();
      fetchTasks();
      fetchNotifications();
      fetchSettings();
      fetchMeetingCount();
    }
  }, [token]);

  return (
    <React.Fragment>
      <Switch>
        <Route path={LOCAL_AUTHORIZE_ENDPOINT}>
          <ConnectedSessionAuthorize />
        </Route>
        <Route path={LOCAL_LOGOUT_ENDPOINT}>
          <ConnectedSessionDestroy />
        </Route>
        <PrivateRoute
          path={LOCAL_PROJECT_ENDPOINT}
          component={TodoProject}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_LABEL_ENDPOINT}
          component={TodoLabelPage}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_INBOX_ENDPOINT}
          component={Todo}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_SHARED_TASKS_ENDPOINT}
          component={SharedTaskPage}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_TODAY_ENDPOINT}
          component={TodoTodayPage}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_UPCOMING_ENDPOINT}
          component={TodoUpcomingPage}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_SEARCH_ENDPOINT}
          component={TodoSearchPage}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_INVITATION_ENDPOINT}
          component={ConnectedInvitation}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_GENERAL_SETTINGS_ENDPOINT}
          component={GeneralSettings}
          loading={loading}
        />
        <PrivateRoute
          path={LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT + MEETING_ACTIVTY_TYPE.value}
          component={Meeting}
          loading={loading}
        />
        <Route>
          <Redirect to={LOCAL_INBOX_ENDPOINT} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setProjectsActionCreator: setProjects,
  setLabelsActionCreator: setLabels,
  setSectionsActionCreator: setSections,
  setTasksActionCreator: setTasks,
  setSessionUserInfoActionCreator: setSessionUserInfo,
  setNotificationsActionCreator: setNotifications,
  setSettingsActionCreator: setSettings,
  setCollaboratorsActionCreator: setCollaborators,
  setMeetingInfoActionCreator: setMeetingInfo,
};

/** connect App to the redux store */
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

/** the default export */
export default ConnectedApp;
