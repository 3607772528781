import React, { useEffect, useState } from 'react';
import { Divider, Button, Avatar, message } from 'antd';
import './TaskShare.scss';
import { GET, POST } from '../../../services/axioService';
import {
  SERVER_INVITATIONS_ENDPOINT,
  SERVER_TASK_INVITATIONS_ENDPOINT,
  SERVER_TASK_INVITATION_REVOKE,
} from '../../../configs/endpoints';
import { axioService } from '../../../services/axioService';
import { connect } from 'react-redux';
import { getSessionUserInfo } from '../../../store/ducks/session';
import { Store } from 'redux';
import { validateEmail } from '../../../services/utility';
import TodoShareUserItem from '../todoShareUserItem/TodoShareUserItem';
import lodash from 'lodash';

const TaskShare = ({
  type,
  taskId,
  userInfo,
  taskInfo,
  setIsModalVisible,
}: any) => {
  const [email, setEmail] = useState<any>('');

  const [invitationList, setInvitationList] = useState<any[]>([]);
  const [acceptedInvitation, setAcceptedInvitation] = useState<any[]>(
    taskInfo?.sharedWith
  );
  const [errorMsg, setErrorMsg] = React.useState<string>('');

  useEffect(() => {
    setAcceptedInvitation(taskInfo?.sharedWith);
  }, [taskInfo]);

  const fetchTaskInvitations = async () => {
    const url = SERVER_TASK_INVITATIONS_ENDPOINT.replace(
      ':subject_type',
      type
    ).replace(':subject_id', taskId);

    const taskInvitationsRes = await axioService(GET, url, {}, true);
    setInvitationList(taskInvitationsRes.data.data);
  };

  useEffect(() => {
    fetchTaskInvitations();
  }, []);
  const onEmailInputChange = (e: any) => {
    e.stopPropagation();
    setEmail(e.target.value);
  };

  const deleteHandler = async (token: any) => {
    try {
      await axioService(
        POST,
        `${SERVER_INVITATIONS_ENDPOINT}/${token}/acceptance`,
        { status: 4 },
        true
      );

      const updatedInvitation = invitationList.filter((item) => {
        return item.token !== token;
      });
      setInvitationList(updatedInvitation);
    } catch (Exception) {
      console.error(Exception);
    }
  };

  const revokeTaskAcceptedInvitation = async (taskId: any, userId: any) => {
    try {
      await axioService(
        POST,
        `${SERVER_TASK_INVITATION_REVOKE}`,
        { task_id: taskId, user_id: userId },
        true
      );

      const updatedInvitation = acceptedInvitation.filter((item) => {
        return item.id !== userId;
      });
      setAcceptedInvitation(updatedInvitation);
    } catch (Exception) {
      console.error(Exception);
    }
  };

  const inviteHandler = async () => {
    try {
      if (email !== '') {
        const invitations = lodash.map(
          invitationList,
          (iterInvitation: any) => iterInvitation.guest_email
        );
        const onBoardedUsers = [...taskInfo.sharedWith, ...invitations];
        if (onBoardedUsers.includes(email.trim())) {
          setErrorMsg('The user is already present or invited.');
          message.error('The user is already present or invited.');
        } else {
          const response = await axioService(
            POST,
            SERVER_INVITATIONS_ENDPOINT,
            {
              subject_type: 'task',
              subject_id: taskInfo.id,
              guest_email: email.trim(),
            },
            true
          );
          setEmail('');
          setErrorMsg('');
          setInvitationList([response?.data, ...invitationList]);
        }
      }
    } catch (exception) {
      /** output the error on the console */
      console.error(exception);

      /** sets the related error msg */
      setErrorMsg(
        exception.response.data?.errors?.guest_email ||
          'Something unexpected happended. Try again later!'
      );
    }
  };

  return (
    <div className="TaskShare-sharetask_wrapper">
      <p className="TaskShare-sharetask_wrapper-title">
        Shared task with others
      </p>

      <div style={{ display: 'flex', gap: '18px' }}>
        <img src="/user.svg" alt="user" />
        <input
          onChange={onEmailInputChange}
          type="text"
          value={email}
          className="TaskShare-sharetask_wrapper-email"
          placeholder="Write email/username to add people"
          onClick={(e: any) => e.stopPropagation()}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (validateEmail(email)) {
                inviteHandler();
              } else {
                message.error('Invalid Email');
              }
            }
          }}
        />
      </div>
      <Divider style={{ marginTop: '5px', border: '1px solid #D2D2D2' }} />
      <p className="TaskShare-sharetask_wrapper-share">
        Shared and Assigned to
      </p>

      <div className="ShareProject-user-item">
        <Avatar size="large" src={userInfo.avatar}>
          <div>{userInfo?.name?.slice(0, 2)}</div>
        </Avatar>
        <div className="ShareProject-user-description">
          <div className="ShareProject-user-name">Me</div>
          <div className="ShareProject-user-email">{userInfo.email || ''}</div>
        </div>
        <div className="ShareProject-user-owner">Owner</div>
        {/* <div
          className="ShareProject-user-delete"
          onClick={projectLeaveRequestHandler}
        >
          <i className="fas fa-sign-out-alt"></i>
        </div> */}
      </div>

      <Divider />

      {acceptedInvitation?.map((user: any, index: any) => {
        return (
          <TodoShareUserItem
            key={user?.email}
            id={user?.email || ''}
            avatar={user?.avatar || ''}
            name={user?.name || 'Guest'}
            email={user?.email || ''}
            pending={false}
            deleteHandler={() => {
              revokeTaskAcceptedInvitation(taskId, user?.id);
            }}
          />
        );
      })}
      {invitationList.map((invitation: any, index: any) => {
        return (
          <TodoShareUserItem
            key={invitation?.id}
            id={invitation?.id || ''}
            avatar={invitation?.invitee?.avatar || ''}
            name={invitation?.invitee?.name || 'Guest'}
            email={invitation?.guest_email || ''}
            pending={true}
            deleteHandler={() => {
              //
              deleteHandler(invitation.token);
            }}
          />
        );
      })}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            setIsModalVisible(false);
          }}
          type="primary"
        >
          Done
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: Partial<Store>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parentProps: any
): any => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
//   const mapDispatchToProps = {
//     setCollaboratorActionCreator: setCollaborator,
//     setProjectActionCreator: setProject,
//     updateProjectsActionCreator: updateProjects,
//     fixProjectOrdersActionCreator: fixProjectOrders,
//     deleteSectionsBasedOnProjectsActionCreator: deleteSectionsBasedOnProjects,
//     deleteTasksBasedOnProjectsActionCreator: deleteTasksBasedOnProjects,
//     removeUserAssignedTasksActionCreator: removeUserAssignedTasks,
//   };

/** connect ShareProject to the redux store */
const ConnectedShareTask = connect(mapStateToProps, {})(TaskShare);

/** the default export */
export default ConnectedShareTask;
