import {
  AutoComplete,
  Button,
  Collapse,
  Dropdown,
  Input,
  message,
  Modal,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  GOOGLE_MEET_MEETING,
  INPERSON_MEETING,
  MEETING_TYPES,
  ONLINE_MEETING,
  ONLINE_MEETING_TYPES,
  ZOOM_MEETING,
} from './constants';
import MeetingEventTitle from '../../components/activities/meetingEventTitle/MeetingEventTitle';
import MeetingEventAgendaItem from '../../components/activities/meetingEventAgendaItem/MeetingEventAgendaItem';
import './MeetingEvent.scss';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { axioService, GET, POST } from '../../services/axioService';
import {
  SERVER_COLLABORATORS_ENDPOINT,
  SERVER_MEETING_CREATE_ENDPOINT,
  SERVER_MEETING_SEND_EMAIL_ENDPOINT,
  SERVER_TASK_TYPES_DETAILS_ENDPOINT,
  SERVER_TASK_TYPES_INTEGRATIONS_ENDPOINT,
} from '../../configs/endpoints';
import lodash from 'lodash';
import TodoShareUserItem from '../../components/todo/todoShareUserItem/TodoShareUserItem';
import { MEETING_ACTIVTY_TYPE } from '../../configs/activityTypes';
import { stateToHTML } from 'draft-js-export-html';
import {
  AgendaStateItem,
  INITIAL_AGENDA_STATE,
} from '../../components/activities/meetingAgenda/MeetingAgenda';
import { getSessionUserInfo } from '../../store/ducks/session';
import { connect } from 'react-redux';
import { Store } from 'redux';
import SortableTree from 'react-sortable-tree';
import UploadToolbar, {
  FileObj,
} from '../../components/todo/uploadToolbar/UploadToolbar';
import CustomDatePicker from '../../components/general/customDatePicker/CustomDatePicker';
import TimePicker from '../../components/general/timePicker/TimePicker';
import moment from 'moment';
import {
  DATE_FORMAT,
  INTERNAL_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../configs/constants';
import RecurrencePicker from '../recurrencePicker/RecurrencePicker';
import { useHistory } from 'react-router-dom';

/** interface to describe the event data */
interface EventData {
  name: string;
  description: any;
  people: any[];
  type: string;
  location: string;
  platform: string;
  link: string;
  attachments: FileObj[];
}

/** interface to describe the mail data */
interface MailData {
  subject: string;
  body: any;
}

/** interface to describe the meeting date data */
interface MeetingDates {
  start: string;
  end: string;
  startTime: string;
  endTime: string;
}

/** initial event data value */
const INITIAL_EVENT_DATA: EventData = {
  name: '',
  description: EditorState.createEmpty(),
  people: [],
  type: ONLINE_MEETING.value,
  location: '',
  platform: '',
  link: '',
  attachments: [],
};

/** initial meeting date value */
const INITIAL_MEETING_DATES: MeetingDates = {
  start: '' || moment().format(DATE_FORMAT),
  end: '' || moment().format(DATE_FORMAT),
  startTime: '',
  endTime: '',
};

/** interface to describe the MeetingEvent props */
interface MeetingEventProps {
  taskId: string;
  meetingDate: string;
  meetingTime: string;
  meetingEndDate: string;
  meetingEndTime: string;
  meetingDurationChangeHandler: (
    requestedStartDate: string,
    requestedEndDate: string,
    requestedStartTime: string,
    requestedEndTime: string
  ) => void;
  userInfo: any;
  taskTitle: string;
  taskDescription: string;
  onRecurringChange: (requested: 0 | 1) => void;
  incrementCounter: () => void;
}

function validateEmail(requestedEmail: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(requestedEmail);
}

const fixDateRange = (meetingDates: MeetingDates): MeetingDates => {
  const tmpStartDate = moment(
    meetingDates.start + ' ' + meetingDates.startTime,
    INTERNAL_DATE_TIME_FORMAT
  );
  const tmpEndDate = moment(
    meetingDates.end + ' ' + meetingDates.endTime,
    INTERNAL_DATE_TIME_FORMAT
  );
  if (tmpStartDate.isBefore(tmpEndDate)) {
    return meetingDates;
  } else {
    tmpStartDate.add(1, 'hour');
    return {
      start: meetingDates.start,
      startTime: meetingDates.startTime,
      end: tmpStartDate.format(DATE_FORMAT),
      endTime: tmpStartDate.format(TIME_FORMAT),
    };
  }
};

const MeetingEvent: React.FC<MeetingEventProps> = (
  props: MeetingEventProps
) => {
  const {
    taskId,
    meetingDate,
    meetingEndDate,
    meetingTime,
    meetingEndTime,
    userInfo,
    taskTitle,
    taskDescription,
    meetingDurationChangeHandler,
    incrementCounter,
    onRecurringChange,
  } = props;
  const { Option } = Select;
  const { Panel } = Collapse;
  const history = useHistory();

  const linkInputRef = React.useRef<any>(null);
  /** keeps reference whether the unsaved data is present or not */
  const unsavedRef = React.useRef<any>(null);

  const modifiedInitialData = {
    ...INITIAL_EVENT_DATA,
    name: taskTitle,
    description: EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(taskDescription) as any)
    ),
  };

  /** event form data  */

  /** manages the event data state */
  const [eventData, setEventData] = React.useState<EventData>(
    modifiedInitialData
  );

  /** manages the previous event data state */
  const [previousEventData, setPreviousEventData] = React.useState<EventData>(
    modifiedInitialData
  );

  /** agenda data */

  /** manages the agenda data state */
  const [agendaData, setAgendaData] = React.useState<AgendaStateItem[]>(
    INITIAL_AGENDA_STATE
  );

  /** manages the previous agenda data state */
  const [previousAgendaData, setPreviousAgendaData] = React.useState<
    AgendaStateItem[]
  >(INITIAL_AGENDA_STATE);

  /** manages the meeting event date data */
  const [meetingDates, setMeetingDates] = React.useState<MeetingDates>(
    INITIAL_MEETING_DATES
  );

  /** manages the previous meeting event date data */
  const [previousMeetingDates, setPreviousMeetingDates] = React.useState<
    MeetingDates
  >(INITIAL_MEETING_DATES);

  /** other state utils */

  /** manages the invitee state */
  const [inviteState, setInviteState] = React.useState<string>('');

  /** manages the connection state */
  const [connection, setConnection] = React.useState<boolean>(true);

  /** manages the token state */
  const [token, setToken] = React.useState<string>('');

  /** manages the recurrence dropdown visibility */
  const [
    recurrenceDropdownVisible,
    setRecurrenceDropdownVisible,
  ] = React.useState<boolean>(false);

  /** cycles */

  /** updates the meeting dates if it changes */
  React.useEffect(() => {
    const roundedUp = Math.ceil(moment().minute() / 15) * 15;

    const tmpMeetingDatesObj: MeetingDates = fixDateRange({
      start: meetingDate || moment().format(DATE_FORMAT),
      end: meetingEndDate || moment().format(DATE_FORMAT),
      startTime: meetingDate
        ? meetingTime
        : moment().minute(roundedUp).second(0).format(TIME_FORMAT),
      endTime: meetingEndDate ? meetingEndTime : moment().format(TIME_FORMAT),
    });
    setMeetingDates(tmpMeetingDatesObj);
    setPreviousMeetingDates(tmpMeetingDatesObj);
  }, [meetingDate, meetingEndDate, meetingTime, meetingEndTime]);

  /** fetch event data from server */
  React.useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_TASK_TYPES_DETAILS_ENDPOINT,
          { type: MEETING_ACTIVTY_TYPE.value, task_id: taskId },
          true
        );

        if (response.data?.event) {
          /** setting previous event data  */
          const modifiedState = {
            ...eventData,
            name: response.data.event?.name || '',
            description:
              (response.data.event?.description &&
                EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(
                      response.data.event?.description as any
                    ) as any
                  )
                )) ||
              EditorState.createEmpty(),
            type: response.data.event?.type || ONLINE_MEETING.value,
            location: response.data.event?.type_info?.address || '',
            people:
              response.data.event?.attendees?.map((iterAttendee: any) => ({
                ...iterAttendee,
                value: iterAttendee.email,
              })) || [],
            platform: response.data.event?.type_info?.medium || '',
            link: response.data.event?.type_info?.medium_properties?.link || '',
            attachments: response.data.event?.attachments || [],
          };
          setEventData(modifiedState);
          setPreviousEventData(modifiedState);

          if (
            [GOOGLE_MEET_MEETING.value, ZOOM_MEETING.value].includes(
              response.data.event?.type_info?.medium
            ) &&
            (response.data.event?.type_info?.medium_properties?.link || '') ===
              ''
          ) {
            try {
              const linkResponse = await axioService(
                POST,
                SERVER_MEETING_CREATE_ENDPOINT,
                {
                  type: MEETING_ACTIVTY_TYPE.value,
                  task_id: taskId,
                  platform: response.data.event?.type_info?.medium,
                },
                true
              );
              setConnection(true);
              setEventData({
                ...eventData,
                link: linkResponse.data.conference.joining_link || '',
                platform: response.data.event?.type_info?.medium,
              });
            } catch (exception) {
              setConnection(false);
              setToken(exception.response.data.token);
              console.error(exception);
            }
          }
        }

        /** setting previous objective data */
        if (response.data?.objectives) {
          const modifiedState = response.data?.objectives.map(
            (iter: AgendaStateItem) => ({
              title: iter.title || '',
              description:
                (iter?.description &&
                  EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(iter?.description as any) as any
                    )
                  )) ||
                EditorState.createEmpty(),
            })
          );
          setAgendaData(modifiedState);
          setPreviousAgendaData(modifiedState);
        }
      } catch (Exception) {
        console.error(Exception);
      }
    };

    /** allow sider animation to complete */
    setTimeout(() => fetchEventData(), 1000);

    return () => {
      if (unsavedRef.current) {
        Modal.confirm({
          className: 'MeetingEvent-confirm-modal',
          content:
            'There are some unsaved information. Do you want to save the changes?',
          okText: 'Save',
          cancelText: 'Discard',
          onOk: async () => {
            await unsavedRef.current();
            incrementCounter();
          },
        });
      }
    };
  }, []);

  /** handlers */

  /** event change handler */

  /** name handler */
  const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEventData({ ...eventData, name: event.target.value });
  };

  /** editor handler */
  const editorChangeHandler = (value: any) => {
    setEventData({ ...eventData, description: value });
  };

  /** invitation handler */

  /** invitation change handler */
  const invitationChangeHandler = (value: string) => {
    setInviteState(value);
  };

  const [options, setOptions] = React.useState<any>([]);

  const onSearch = async (searchText: string) => {
    try {
      if (inviteState !== '') {
        const response = await axioService(
          GET,
          SERVER_COLLABORATORS_ENDPOINT,
          {
            username: searchText,
            limit: 100,
          },
          true
        );
        setOptions(
          response.data.data.map((iterCollaborator: any): any => ({
            id: iterCollaborator?.id?.toString() || '',
            name: iterCollaborator.name || '',
            email: iterCollaborator.email || '',
            mobile: iterCollaborator.mobile || '',
            avatar: iterCollaborator.avatar || '',
            value: iterCollaborator.email || '',
          }))
        );
      }
    } catch (exception) {
      /** output the error on the console */
      console.error(exception);
    }
  };

  /** invite handler */
  const inviteHandler = (invitee: any) => {
    setEventData({
      ...eventData,
      people: [{ ...invitee, is_going: null }, ...eventData.people],
    });
  };

  /** invitee enter handler */
  const inputKeyUp = (e: any) => {
    e.which = e.which || e.keyCode;
    if (e.which == 13) {
      /** take no action on empty string */
      if (inviteState === '') {
        return;
      }

      /** not valid email */
      if (!validateEmail(inviteState)) {
        message.error('Not a valid email!');
        return;
      }

      const invitee = lodash.find(options, { email: inviteState });
      if (invitee) {
        inviteHandler(invitee);
      } else {
        inviteHandler({
          id: '',
          name: 'Guest',
          email: inviteState,
          mobile: '',
          avatar: '',
          value: inviteState,
        });
      }
      setInviteState('');
      setOptions([]);
    }
  };

  /** invitee select handler */
  const onSelect = (data: string) => {
    const invitee = lodash.find(options, { email: data });
    inviteHandler(invitee);
    setInviteState('');
    setOptions([]);
  };

  /** invitee delete handler */
  const removeInviteeHandler = (removedInvitee: string) => {
    setEventData({
      ...eventData,
      people: lodash.filter(
        eventData.people,
        (invitee) => invitee.email !== removedInvitee
      ),
    });
  };

  /** type handler */
  const typeChangeHandler = (value: string) => {
    setEventData({
      ...eventData,
      type: value,
      location: '',
      link: '',
      platform: '',
    });
  };

  /** location handler */
  const locationChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEventData({ ...eventData, location: event.target.value });
  };

  /** type handler */
  const platformChangeHandler = async (value: string) => {
    setEventData({
      ...eventData,
      link: '',
      platform: value,
    });

    try {
      const linkResponse = await axioService(
        POST,
        SERVER_MEETING_CREATE_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: taskId,
          platform: value,
        },
        true
      );
      setConnection(true);
      setEventData({
        ...eventData,
        link: linkResponse.data.conference.joining_link || '',
        platform: value,
      });
    } catch (exception) {
      setConnection(false);
      setToken(exception.response.data.token);
      console.error(exception);
    }
  };

  /** link Change handler */
  const linkChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEventData({ ...eventData, link: event.target.value });
  };

  /** attachment change handler */
  const setAttachments = (requestedAttachments: FileObj[]) =>
    setEventData({ ...eventData, attachments: requestedAttachments });

  /** connect handler */
  const connectHandler = async () => {
    await commitHandler();
    try {
      window.location.href = `${SERVER_TASK_TYPES_INTEGRATIONS_ENDPOINT}/${
        eventData.platform
      }?token=${encodeURIComponent(token)}&redirect_url=${encodeURIComponent(
        window.location.href
      )}`;
    } catch (Exception) {
      message.error('Request failed. Please try again after some time.');
      console.error(Exception);
    }
  };

  /** agenda change handler */

  /** agenda state change handler */
  const onAgendaChange = (modifiedState: AgendaStateItem[]) => {
    setAgendaData(modifiedState);
  };

  /** remove agenda item handler */
  const removeAgendaItem = (index: number) => {
    const modifiedState = [...agendaData];
    modifiedState.splice(index, 1);
    if (modifiedState.length === 0) {
      setAgendaData(INITIAL_AGENDA_STATE);
    } else {
      setAgendaData(modifiedState);
    }
  };

  /** add agenda handler */
  const addAgenda = () => {
    setAgendaData([...agendaData, ...INITIAL_AGENDA_STATE]);
  };

  /** utils */
  const isEventDataUnchanged = () => {
    return (
      JSON.stringify({
        ...previousEventData,
        description: stateToHTML(
          previousEventData.description.getCurrentContent()
        ).toString(),
      }) ===
        JSON.stringify({
          ...eventData,
          description: stateToHTML(
            eventData.description.getCurrentContent()
          ).toString(),
        }) && JSON.stringify(agendaData) === JSON.stringify(previousAgendaData)
    );
  };

  const isEventDataEqualToInitial = () => {
    return (
      JSON.stringify({
        ...modifiedInitialData,
        description: stateToHTML(
          modifiedInitialData.description.getCurrentContent()
        ).toString(),
      }) ===
        JSON.stringify({
          ...eventData,
          description: stateToHTML(
            eventData.description.getCurrentContent()
          ).toString(),
        }) &&
      JSON.stringify(agendaData) === JSON.stringify(INITIAL_AGENDA_STATE)
    );
  };

  const isMeetingDatesUnchanged = () => {
    return (
      JSON.stringify(meetingDates) === JSON.stringify(previousMeetingDates)
    );
  };

  /** submit and next handlers */

  const commitHandler = async () => {
    if (
      isEventDataUnchanged() &&
      isMeetingDatesUnchanged() &&
      JSON.stringify(agendaData) === JSON.stringify(previousAgendaData)
    ) {
      return;
    }
    let modifiedPeople = [...eventData.people];
    if (!lodash.find(eventData.people, { email: userInfo.email })) {
      modifiedPeople = [
        ...modifiedPeople,
        {
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          mobile: userInfo.mobile,
          avatar: userInfo.avatar,
          value: userInfo.email,
        },
      ];
      setEventData({ ...eventData, people: modifiedPeople });
    }
    meetingDurationChangeHandler(
      meetingDates.start,
      meetingDates.end,
      meetingDates.startTime,
      meetingDates.endTime
    );
    try {
      await axioService(
        POST,
        SERVER_TASK_TYPES_DETAILS_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: taskId,
          event: {
            name: eventData.name,
            description: stateToHTML(
              eventData.description.getCurrentContent()
            ).toString(),
            type: eventData.type,
            duration: 30,
            type_info: {
              address: eventData.location,
              medium: eventData.platform,
              medium_properties: {
                link: eventData.link,
              },
            },
            attendees: modifiedPeople,
            attachments: eventData.attachments,
          },
          objectives: agendaData.map((iter) => ({
            ...iter,
            description: stateToHTML(
              iter.description.getCurrentContent()
            ).toString(),
          })),
        },
        true
      );

      setPreviousAgendaData(agendaData);
      setPreviousEventData({ ...eventData, people: modifiedPeople });
      setPreviousMeetingDates(meetingDates);

      message.success('Saved!');
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to save');
    }
  };

  /** modifies the unsaved ref with each update */
  unsavedRef.current =
    isEventDataUnchanged() && isMeetingDatesUnchanged() ? null : commitHandler;

  /** submit handler */
  const onSubmit = async () => {
    let modifiedPeople = [...eventData.people];
    if (!lodash.find(eventData.people, { email: userInfo.email })) {
      modifiedPeople = [
        ...modifiedPeople,
        {
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          mobile: userInfo.mobile,
          avatar: userInfo.avatar,
          value: userInfo.email,
        },
      ];
      setEventData({ ...eventData, people: modifiedPeople });
    }
    meetingDurationChangeHandler(
      meetingDates.start,
      meetingDates.end,
      meetingDates.startTime,
      meetingDates.endTime
    );
    try {
      const response = await axioService(
        POST,
        SERVER_TASK_TYPES_DETAILS_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: taskId,
          event: {
            name: eventData.name,
            description: stateToHTML(
              eventData.description.getCurrentContent()
            ).toString(),
            type: eventData.type,
            duration: 30,
            type_info: {
              address: eventData.location,
              medium: eventData.platform,
              medium_properties: {
                link: eventData.link,
              },
            },
            attendees: modifiedPeople,
            attachments: eventData.attachments,
          },
          objectives: agendaData.map((iter) => ({
            ...iter,
            description: stateToHTML(
              iter.description.getCurrentContent()
            ).toString(),
          })),
        },
        true
      );
      setPreviousAgendaData(agendaData);
      setPreviousMeetingDates(meetingDates);

      if (
        [GOOGLE_MEET_MEETING.value, ZOOM_MEETING.value].includes(
          eventData.platform
        )
      ) {
        const modifiedState = {
          ...eventData,
          people: modifiedPeople,
          link: response.data.event?.type_info?.medium_properties?.link || '',
        };
        setEventData(modifiedState);
        setPreviousEventData(modifiedState);
      } else {
        setPreviousEventData(eventData);
      }
      // Modal.confirm({
      //   className: 'MeetingEvent-confirm-modal',
      //   content:
      //     'Your meeting has been saved. Would you like to send email to your invited guests?',
      //   okText: 'Send Email',
      //   cancelText: "Don't Send",
      //   onOk: async () => {
      //     try {
      //       await axioService(
      //         POST,
      //         SERVER_MEETING_SEND_EMAIL_ENDPOINT,
      //         {
      //           type: MEETING_ACTIVTY_TYPE.value,
      //           task_id: taskId,
      //         },
      //         true
      //       );
      //     } catch (exception) {
      //       console.error(exception);
      //       message.error('Failed to send');
      //     }
      //   },
      // });
      history.push({ search: '' });
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to save meeting!');
    }
  };

  const treeChangeHandler = (modifiedTree: any) => setAgendaData(modifiedTree);

  const copyLinkOnClipboard = () => {
    if (linkInputRef.current) {
      linkInputRef.current.select();
      document.execCommand('Copy');
      linkInputRef.current.blur();
    }
  };

  const closeRecurrenceHandler = () => setRecurrenceDropdownVisible(false);

  const onRecurrenceDropdownVisibleChange = (requested: boolean) =>
    setRecurrenceDropdownVisible(requested);

  return (
    <div className="MeetingEvent-container">
      <Collapse accordion expandIconPosition="right" activeKey={['1']}>
        <Panel
          key="1"
          header={
            <div className="MeetingEvent-header">
              <MeetingEventTitle
                color="#6392f1"
                icon={'far fa-calendar'}
                title="Create your Event"
                subtitle={'Write your event name here!'}
              />
              {isEventDataUnchanged() && isMeetingDatesUnchanged() ? (
                !isEventDataEqualToInitial() && (
                  <Tag className="MeetingEvent-tag" color="green">
                    Saved
                  </Tag>
                )
              ) : (
                <Tag className="MeetingEvent-tag" color="orange">
                  Unsaved
                </Tag>
              )}
            </div>
          }
        >
          <div className="MeetingEvent-body">
            <div className="MeetingEvent-field-container">
              <div className="MeetingEvent-field-label">
                Event Name<span className="MeetingEvent-required">*</span>
              </div>
              <div className="MeetingEvent-field-body">
                <Input
                  value={eventData.name}
                  onChange={nameChangeHandler}
                  placeholder="Write your event title here"
                />
              </div>
            </div>
            <div className="MeetingEvent-field-container">
              <div className="MeetingEvent-field-label">Description</div>
              <div className="MeetingEvent-field-body">
                <Editor
                  editorState={eventData.description}
                  onEditorStateChange={editorChangeHandler}
                  toolbar={{
                    options: ['history', 'inline', 'link', 'list'],
                    history: { options: ['undo', 'redo'] },
                    inline: { options: ['bold', 'italic', 'underline'] },
                    link: { options: ['link'] },
                    list: {
                      options: ['ordered', 'unordered', 'indent', 'outdent'],
                    },
                  }}
                  placeholder="Write a short description here"
                  toolbarCustomButtons={[
                    <UploadToolbar
                      fileList={eventData.attachments}
                      setFileList={setAttachments}
                      key="upload"
                    />,
                  ]}
                />
              </div>
            </div>
            <div className="MeetingEvent-field-container">
              <div className="MeetingEvent-field-label">
                Schedule<span className="MeetingEvent-required">*</span>
              </div>
              <div className="MeetingEvent-field-body MeetingEvent-schedule-body">
                <CustomDatePicker
                  selectedDate={new Date(meetingDates.start)}
                  dateChangeHandler={(requested: Date) => {
                    setMeetingDates(
                      fixDateRange({
                        ...meetingDates,
                        start: moment(requested).format(DATE_FORMAT),
                      })
                    );
                  }}
                  minDate={new Date()}
                />
                <TimePicker
                  selectedTime={meetingDates.startTime}
                  timeChangeHandler={(requested: string) => {
                    setMeetingDates(
                      fixDateRange({
                        ...meetingDates,
                        startTime: requested,
                      })
                    );
                  }}
                  use12HoursFormat={true}
                />
                <div className="MeetingEvent-space">To</div>
                <CustomDatePicker
                  selectedDate={new Date(meetingDates.end)}
                  dateChangeHandler={(requested: Date) => {
                    setMeetingDates(
                      fixDateRange({
                        ...meetingDates,
                        end: moment(requested).format(DATE_FORMAT),
                      })
                    );
                  }}
                  minDate={new Date(meetingDates.start)}
                />
                <TimePicker
                  selectedTime={meetingDates.endTime}
                  timeChangeHandler={(requested: string) => {
                    setMeetingDates(
                      fixDateRange({
                        ...meetingDates,
                        endTime: requested,
                      })
                    );
                  }}
                  use12HoursFormat={true}
                />
                <Dropdown
                  overlay={
                    <RecurrencePicker
                      taskId={taskId}
                      closeHandler={closeRecurrenceHandler}
                      backHandler={closeRecurrenceHandler}
                      onRecurringChange={onRecurringChange}
                    />
                  }
                  placement="bottomLeft"
                  trigger={['click']}
                  visible={recurrenceDropdownVisible}
                  onVisibleChange={onRecurrenceDropdownVisibleChange}
                >
                  <Tooltip title="Set recurring" placement="bottom">
                    <Button size="small" className="MeetingEvent-recurring-btn">
                      <i className="fas fa-redo-alt"></i>
                    </Button>
                  </Tooltip>
                </Dropdown>
              </div>
            </div>
            <div className="MeetingEvent-field-container">
              <div className="MeetingEvent-field-label">
                Invite People<span className="MeetingEvent-required">*</span>
              </div>
              <div className="MeetingEvent-field-body">
                <AutoComplete
                  className="MeetingEvent-autocomplete"
                  dropdownClassName="MeetingEvent-autocomplete"
                  value={inviteState}
                  onChange={invitationChangeHandler}
                  onSearch={onSearch}
                  onSelect={onSelect}
                  options={options}
                  onKeyUp={inputKeyUp}
                  placeholder="Search by Email"
                />
                {lodash.map(eventData.people, (invitee: any, index: number) => (
                  <TodoShareUserItem
                    key={'invitee_' + index}
                    id={invitee.email}
                    avatar={invitee.avatar || ''}
                    name={invitee.name || 'Guest'}
                    email={invitee.email || ''}
                    pending={false}
                    deleteHandler={removeInviteeHandler}
                  />
                ))}
              </div>
            </div>
            <div className="MeetingEvent-field-container">
              <div className="MeetingEvent-field-label">
                Meeting Type<span className="MeetingEvent-required">*</span>
              </div>
              <div className="MeetingEvent-field-body">
                <Select
                  dropdownClassName="MeetingEvent-field-body"
                  value={eventData.type}
                  onChange={typeChangeHandler}
                >
                  {MEETING_TYPES.map((option, index) => (
                    <Option key={'g_option_' + index} value={option.value}>
                      <i
                        className={option.icon}
                        style={{ color: option.color }}
                      />
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {eventData.type === INPERSON_MEETING.value && (
              <div className="MeetingEvent-field-container">
                <div className="MeetingEvent-field-label">Meeting Location</div>
                <div className="MeetingEvent-field-body">
                  <Input
                    value={eventData.location}
                    onChange={locationChangeHandler}
                    placeholder="House 804, Road 11, Avenue 4, Mirpur DOHS, Dhaka"
                  />
                </div>
              </div>
            )}
            {eventData.type === ONLINE_MEETING.value && (
              <div className="MeetingEvent-field-container">
                <div className="MeetingEvent-field-label">
                  Meeting Platform
                  <span className="MeetingEvent-required">*</span>
                </div>
                <div className="MeetingEvent-field-body">
                  <Select
                    dropdownClassName="MeetingEvent-field-body"
                    value={
                      eventData.platform === '' ? undefined : eventData.platform
                    }
                    onChange={platformChangeHandler}
                    placeholder="Select Platform"
                  >
                    {ONLINE_MEETING_TYPES.map((option, index) => (
                      <Option key={'o_option_' + index} value={option.value}>
                        <img
                          className="MeetingEvent-icon"
                          width={18}
                          src={option.url}
                          alt=""
                        />{' '}
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  {[GOOGLE_MEET_MEETING.value, ZOOM_MEETING.value].includes(
                    eventData.platform
                  ) &&
                    !connection && (
                      <div className="MeetingEvent-tip">
                        Account not connected.{' '}
                        <Button
                          className="MeetingEvent-connect-btn"
                          type="text"
                          onClick={connectHandler}
                        >
                          Connect now
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            )}
            {eventData.type === ONLINE_MEETING.value && (
              <div className="MeetingEvent-field-container">
                <div className="MeetingEvent-field-label">Meeting Link</div>
                <div className="MeetingEvent-field-body MeetingEvent-link-container">
                  <a
                    href={eventData.link}
                    target="blank"
                    className={eventData.link === '' ? 'disable' : ''}
                  >
                    {eventData.platform !== 'others' && (
                      <Input
                        ref={linkInputRef}
                        readOnly
                        value={eventData.link}
                        onChange={linkChangeHandler}
                        placeholder="Generated meeting link"
                        className="MeetingEvent-link-input"
                      />
                    )}
                  </a>
                  {eventData.platform === 'others' && (
                    <Input
                      ref={linkInputRef}
                      value={eventData.link}
                      onChange={linkChangeHandler}
                      placeholder="Paste meeting link here"
                      className="MeetingEvent-link-input"
                    />
                  )}
                  {eventData.link !== '' && (
                    <div>
                      <Button
                        className="MeetingEvent-link-btn"
                        onClick={copyLinkOnClipboard}
                      >
                        <i className="far fa-clone" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="MeetingEvent-field-label">Agenda</div>
            <SortableTree
              dndType="completedTask"
              isVirtualized={false}
              treeData={agendaData}
              generateNodeProps={({ path }: any) => ({
                title: (
                  <MeetingEventAgendaItem
                    index={path[0]}
                    agendaState={agendaData}
                    onAgendaChange={onAgendaChange}
                    addHandler={addAgenda}
                    removeHandler={removeAgendaItem}
                  />
                ),
              })}
              onChange={treeChangeHandler}
              maxDepth={1}
            />
            <Button
              className="MeetingEvent-draft-btn"
              type="text"
              disabled={isEventDataUnchanged() && isMeetingDatesUnchanged()}
              onClick={commitHandler}
            >
              Save as draft
            </Button>
            <Button
              onClick={onSubmit}
              type="primary"
              className="MeetingEvent-create-btn"
              disabled={
                eventData.name === '' ||
                eventData.type === '' ||
                eventData.platform === '' ||
                meetingDate === ''
              }
            >
              Save
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

/** connect the component with the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: any;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect MeetingEvent to the redux store */
const ConnectedMeetingEvent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingEvent);

export default ConnectedMeetingEvent;
