import React, { useEffect, useState } from 'react';
import withTodoLayout from '../../../hocs/withTodoLayout/withTodoLayout';
import './SharedTaskPage.scss';
import withTaskSider from '../../../hocs/withTaskSider/withTaskSider';
import TodoTodayContent from '../../../containers/todoTodayContent/TodoTodayContent';
import {
  SERVER_SHARED_TASK_ENDPOINT,
  SERVER_SHARED_TASK_UPDATE_ENDPOINT,
} from '../../../configs/endpoints';
import { connect } from 'react-redux';
import { getSessionUserInfo } from '../../../store/ducks/session';
import { Store } from 'redux';
import { axioService, GET, POST } from '../../../services/axioService';
import { template } from 'lodash';
import { setTask, TaskObj } from '../../../store/ducks/tasks';
import moment from 'moment';
import { SharedTaskItem } from './TaskItem/SharedTaskItem';
import {
  transformTaskFromServer,
  transformTaskToServer,
} from '../../../api/tasks/transformer';
import { ServerTaskObj } from '../../../api/tasks/data';
import { TaskType } from './TaskItem/SharedTaskItem';
import { message } from 'antd';
interface SharedTaskPageProps {
  userInfo: any;
}

const SharedTaskPage: React.FC<SharedTaskPageProps> = ({ userInfo }) => {
  // useEffect(()=> {

  // }, []);

  const [tasks, setTasks] = useState<any>([]);
  const [todo, setTodo] = useState<any>([]);
  const [overdue, setOverdue] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);

  const filterTasks = (tasks: TaskObj[]) => {
    const filteredTodo = tasks.filter((task: TaskObj) => {
      const due_date = moment(`${task.dueDate} ${task.dueTime}`);
      return due_date.isAfter(moment(new Date())) && !task.completed;
    });
    setTodo(filteredTodo);
    const filteredDue = tasks.filter((task: TaskObj) => {
      const due_date = moment(`${task.dueDate} ${task.dueTime}`);
      return (
        (due_date.isBefore(moment(new Date())) && !task.completed) ||
        (task.dueDate == '' && !task.completed)
      );
    });
    setOverdue(filteredDue);

    const filteredComplete = tasks.filter((task: TaskObj) => {
      return task.completed == true;
    });
    setCompleted(filteredComplete);
  };

  const fetchTasks = async () => {
    const url = SERVER_SHARED_TASK_ENDPOINT.replace(
      ':username',
      userInfo.username
    );

    const tasks = await axioService(GET, url, {}, true);
    /**
     * demo data
     */
    // let tasks: any = await Promise.resolve({
    //   data: [
    //     {
    //       id: '5464-8c65-69ec6a892a65',
    //       title: 'jjjkjjkjk',
    //       description: null,
    //       project_id: null,
    //       section_id: null,
    //       priority: '1',
    //       due_date: '2022-10-18 06:00:00',
    //       end_date: null,
    //       parent_id: null,
    //       order: 18,
    //       is_expanded: 1,
    //       is_completed: 0,
    //       completed_by: null,
    //       assigned_to: null,
    //       is_recurring: 0,
    //       reference_id: null,
    //       recurrence_id: null,
    //       recurring_schedule_date: null,
    //       labels: [
    //         'c4d5e1b7-34aa-4e3c-820e-b0a9f18d9e24',
    //         '3e69c2ad-4d61-4c27-b1e0-3d1bc3826693',
    //       ],
    //       comments_count: 3,
    //       comments: [1, 2, 4],
    //       reminders: [],
    //       shared_with: [
    //         {
    //           id: 66,
    //           name: 'dev user',
    //           username: 'devdevuser',
    //           email: 'user@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: 'Male',
    //           profession: null,
    //           date_of_birth: null,
    //           city: 'Dhaka',
    //           country: 'Bangladesh',
    //           connected_with: [],
    //         },
    //         {
    //           id: 620,
    //           name: 'Virutunus User 632',
    //           username: 'user329',
    //           email: 'user329@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: null,
    //           profession: null,
    //           date_of_birth: null,
    //           city: null,
    //           country: null,
    //           connected_with: [],
    //         },
    //       ],
    //       type: 'meeting',
    //       created_by: 319,
    //       created_at: '2022-10-25 06:04:32',
    //       is_exists: 1,
    //     },
    //     {
    //       id: 'aad-dba2-5464-8c65-69ec6a8334a65',
    //       title: 'task2',
    //       description: null,
    //       project_id: null,
    //       section_id: null,
    //       priority: '2',
    //       due_date: '2022-11-18 06:00:00',
    //       end_date: null,
    //       parent_id: null,
    //       order: 18,
    //       is_expanded: 1,
    //       is_completed: 0,
    //       completed_by: null,
    //       assigned_to: null,
    //       is_recurring: 0,
    //       reference_id: null,
    //       recurrence_id: null,
    //       recurring_schedule_date: null,
    //       labels: [],
    //       comments_count: 0,
    //       comments: [],
    //       reminders: [],
    //       shared_with: [
    //         {
    //           id: 66,
    //           name: 'dev user',
    //           username: 'devdevuser',
    //           email: 'user@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: 'Male',
    //           profession: null,
    //           date_of_birth: null,
    //           city: 'Dhaka',
    //           country: 'Bangladesh',
    //           connected_with: [],
    //         },
    //         {
    //           id: 620,
    //           name: 'Virutunus User 632',
    //           username: 'user329',
    //           email: 'user329@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: null,
    //           profession: null,
    //           date_of_birth: null,
    //           city: null,
    //           country: null,
    //           connected_with: [],
    //         },
    //       ],
    //       type: null,
    //       created_by: 319,
    //       created_at: '2022-10-25 06:04:32',
    //       is_exists: 1,
    //     },
    //     {
    //       id: 'aad-dba2-5464--69ec6a8334a65',
    //       title: 'task3',
    //       description: null,
    //       project_id: null,
    //       section_id: null,
    //       priority: '3',
    //       due_date: '2022-11-18 06:00:00',
    //       end_date: null,
    //       parent_id: null,
    //       order: 18,
    //       is_expanded: 1,
    //       is_completed: 0,
    //       completed_by: null,
    //       assigned_to: null,
    //       is_recurring: 1,
    //       reference_id: null,
    //       recurrence_id: null,
    //       recurring_schedule_date: null,
    //       labels: [],
    //       comments_count: 0,
    //       comments: [],
    //       reminders: [],
    //       shared_with: [
    //         {
    //           id: 66,
    //           name: 'dev user',
    //           username: 'devdevuser',
    //           email: 'user@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: 'Male',
    //           profession: null,
    //           date_of_birth: null,
    //           city: 'Dhaka',
    //           country: 'Bangladesh',
    //           connected_with: [],
    //         },
    //         {
    //           id: 620,
    //           name: 'Virutunus User 632',
    //           username: 'user329',
    //           email: 'user329@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: null,
    //           profession: null,
    //           date_of_birth: null,
    //           city: null,
    //           country: null,
    //           connected_with: [],
    //         },
    //       ],
    //       type: null,
    //       created_by: 319,
    //       created_at: '2022-10-25 06:04:32',
    //       is_exists: 1,
    //     },
    //     {
    //       id: '95-69ec906a8334a65',
    //       title: 'task4',
    //       description: null,
    //       project_id: null,
    //       section_id: null,
    //       priority: '3',
    //       due_date: '2022-11-18 06:00:00',
    //       end_date: null,
    //       parent_id: null,
    //       order: 18,
    //       is_expanded: 1,
    //       is_completed: 1,
    //       completed_by: null,
    //       assigned_to: null,
    //       is_recurring: 1,
    //       reference_id: null,
    //       recurrence_id: null,
    //       recurring_schedule_date: null,
    //       labels: [],
    //       comments_count: 0,
    //       comments: [],
    //       reminders: [],
    //       shared_with: [
    //         {
    //           id: 66,
    //           name: 'dev user',
    //           username: 'devdevuser',
    //           email: 'user@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: 'Male',
    //           profession: null,
    //           date_of_birth: null,
    //           city: 'Dhaka',
    //           country: 'Bangladesh',
    //           connected_with: [],
    //         },
    //         {
    //           id: 620,
    //           name: 'Virutunus User 632',
    //           username: 'user329',
    //           email: 'user329@virtunus.com',
    //           mobile: null,
    //           avatar: null,
    //           is_verified: false,
    //           is_active: true,
    //           is_email_verified: false,
    //           gender: null,
    //           profession: null,
    //           date_of_birth: null,
    //           city: null,
    //           country: null,
    //           connected_with: [],
    //         },
    //       ],
    //       type: null,
    //       created_by: 319,
    //       created_at: '2022-10-25 06:04:32',
    //       is_exists: 1,
    //     },
    //   ],
    // });
    const mappedTasks = tasks?.data?.data?.map((task: any) => {
      return transformTaskFromServer(task);
    });
    filterTasks(mappedTasks as any);
    setTasks(mappedTasks);
  };

  useEffect(() => {
    fetchTasks();
  }, []);
  useEffect(() => {
    filterTasks(tasks);
  }, [tasks]);
  //
  const updateTask = async (task: TaskObj | null) => {
    //
    const url = SERVER_SHARED_TASK_UPDATE_ENDPOINT.replace(
      ':task_id',
      (task as TaskObj).id
    );
    const serverTask = transformTaskToServer(task as TaskObj);
    // console.log('update task', serverTask);
    const response = await axioService(POST, url, serverTask, true);
    // console.log('updated task response', response);
  };
  const onCheck = async (taskId: string) => {
    const tasksClone = [...tasks];
    let complete_status = false;
    let clickedTask: TaskObj | null = null;
    const updatedTasks = tasksClone.map((task: TaskObj, index) => {
      if (task.id !== taskId) {
        return task;
      } else {
        const clonedTask: TaskObj = { ...task };
        complete_status = clonedTask.completed;
        clonedTask.completed = !clonedTask.completed;
        clickedTask = clonedTask;
        return clonedTask;
      }
    });
    try {
      await updateTask(clickedTask);
      setTasks(updatedTasks);
      if (complete_status === false) {
        message.success('task executed');
      } else {
        message.warn('task undo');
      }
    } catch (e) {
      message.error('could not update task');
      //
    }
  };
  return (
    <div className="SharedTask">
      <p className="SharedTask-text">Shared with me</p>
      <section className="SharedTask-overdue">
        <p className="SharedTask-overdue-text">Overdue</p>
        <div>
          {overdue.map((task: TaskObj) => {
            return (
              <SharedTaskItem
                taskType={TaskType.Due}
                task={task}
                key={task.id}
                onCheck={onCheck}
              />
            );
          })}
        </div>
      </section>
      <section className="SharedTask-todo">
        <p className="SharedTask-todo-text">Todo</p>
        <div>
          {todo.map((task: TaskObj) => {
            return (
              <SharedTaskItem
                taskType={TaskType.Todo}
                task={task}
                key={task.id}
                onCheck={onCheck}
              />
            );
          })}
        </div>
      </section>
      <section className="SharedTask-completed">
        <p className="SharedTask-completed-text">Completed</p>
        <div>
          {completed.map((task: TaskObj) => {
            return (
              <SharedTaskItem
                taskType={TaskType.Completed}
                task={task}
                key={task.id}
                onCheck={onCheck}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (
  state: Partial<Store>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parentProps: any
): any => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
//   const mapDispatchToProps = {
//     setCollaboratorActionCreator: setCollaborator,
//     setProjectActionCreator: setProject,
//     updateProjectsActionCreator: updateProjects,
//     fixProjectOrdersActionCreator: fixProjectOrders,
//     deleteSectionsBasedOnProjectsActionCreator: deleteSectionsBasedOnProjects,
//     deleteTasksBasedOnProjectsActionCreator: deleteTasksBasedOnProjects,
//     removeUserAssignedTasksActionCreator: removeUserAssignedTasks,
//   };

/** connect ShareProject to the redux store */
const ConnectedShareTask = connect(mapStateToProps, {})(SharedTaskPage);

/** the default export */

export default withTaskSider(withTodoLayout(ConnectedShareTask));
