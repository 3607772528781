/** general meeting types */

/** online meeting type */
export const ONLINE_MEETING = {
  label: 'Online Meeting',
  value: 'online',
  icon: 'fas fa-users',
  color: '#06bdae',
};

export type ONLINE_MEETING = typeof ONLINE_MEETING;

/** inperson meeting type */
export const INPERSON_MEETING = {
  label: 'In Person Meeting',
  value: 'in_person',
  icon: 'fas fa-laptop-code',
  color: '#f27753',
};

export type INPERSON_MEETING = typeof INPERSON_MEETING;

/** meeting types */
export const MEETING_TYPES = [ONLINE_MEETING, INPERSON_MEETING];

/** online meeting types */

/** phone call meeting type */
export const PHONE_CALL_MEETING = {
  label: 'Phone Call',
  value: 'phone_call',
  url: '/phone-solid.svg',
};

export type PHONE_CALL_MEETING = typeof PHONE_CALL_MEETING;

/** google meet meeting type */
export const GOOGLE_MEET_MEETING = {
  label: 'Google Meet',
  value: 'meet',
  url: '/google-meet.svg',
};

export type GOOGLE_MEET_MEETING = typeof GOOGLE_MEET_MEETING;

/** zoom meeting type */
export const ZOOM_MEETING = {
  label: 'Zoom',
  value: 'zoom',
  url: '/zoom.svg',
};

export type ZOOM_MEETING = typeof ZOOM_MEETING;

/*
 * other
 */

export const OTHER_PLATFORM = {
  label: 'Others',
  value: 'others',
  url: '/user.svg',
};

/** online meeting types */
export const ONLINE_MEETING_TYPES = [
  GOOGLE_MEET_MEETING,
  ZOOM_MEETING,
  OTHER_PLATFORM,
];
