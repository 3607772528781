import { Avatar, Checkbox, Tooltip } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { TaskObj } from '../../../../store/ducks/tasks';
import ConnectedTodoTaskLabelTag from '../../../../containers/todoTaskLabelTag/TodoTaskLabelTag';
import {
  LOW_PRIORITY_VALUE,
  MIDDLE_PRIORITY_VALUE,
  NO_PRIORITY_VALUE,
  TOP_PRIORITY_VALUE,
} from '../../todoPriorityDropdown/constants';
import './SharedTaskItem.scss';

export enum TaskType {
  Todo = 'Todo',
  Due = 'Due',
  Completed = 'Completed',
}
interface SharedTaskItemProps {
  task: TaskObj;
  taskType: TaskType;
  onCheck: any;
}

export const SharedTaskItem = (props: SharedTaskItemProps) => {
  const { task, taskType, onCheck } = props;
  const stopEventBubbling = () => {
    //
  };
  const onComplete = () => {
    //
  };
  const date_merge = `${task.dueDate} ${task.dueTime}`;
  const date_format =
    task.dueDate != '' ? moment(date_merge).format('hh:mm A') : '';
  // console.log(moment(date_format).format('hh:mm A'));
  const text = <span>Shared By {task.createdBy}</span>;
  return (
    <Tooltip placement="top" title={text}>
      <div className="SharedTaskItem">
        <div className="SharedTaskItem-checkbox_wrapper">
          <Checkbox
            onClick={stopEventBubbling}
            checked={false}
            onChange={() => {
              onCheck(task.id);
            }}
            className={clsx(
              !task.completed
                ? {
                    //   'TaskItem-checkbox': true,
                    'top-priority': task.priority === TOP_PRIORITY_VALUE,
                    'middle-priority': task.priority === MIDDLE_PRIORITY_VALUE,
                    'low-priority': task.priority === LOW_PRIORITY_VALUE,
                    'no-priority': task.priority === NO_PRIORITY_VALUE,
                  }
                : {
                    'completed-task': true,
                  }
            )}
          />
          <div>
            <span
              className={clsx({
                'SharedTaskItem-completed': task.completed,
                'SharedTaskItem-title': true,
              })}
            >
              {task.title}
            </span>
            <div className="task_meta_container">
              {task.activityType === 'meeting' && (
                <img src="/meetinglogo.svg" alt="meeting" />
              )}
              {task.commentsCount > 0 && (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <img src="/messageIcon.svg" alt="message" />
                  <span>{task.commentsCount}</span>
                </div>
              )}
              {task.commentsCount > 0 && <img src="/repeat.svg" alt="repeat" />}
              {task.commentsCount > 0 && (
                <img src="/alarmClock.svg" alt="alarm" />
              )}
              <div style={{ display: 'flex' }}>
                {task.labels.map((iterlabel: string, index: number) => (
                  <ConnectedTodoTaskLabelTag
                    id={iterlabel}
                    key={task.id + '-label-' + index}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="SharedTaskItem-date_wrapper">
          <span>
            <Avatar.Group maxCount={3}>
              {(task.sharedWith as Array<any>)?.map(
                (iterCollaboratorId: any, iterCollaboratorIndex: number) => {
                  return (
                    <Avatar
                      size="small"
                      key={`collaborator-${iterCollaboratorIndex}`}
                      src={iterCollaboratorId?.avatar}
                    >
                      <div>{iterCollaboratorId?.name?.slice(0, 2)}</div>
                    </Avatar>
                  );
                }
              )}
            </Avatar.Group>
          </span>
          <span
            className={clsx({
              'SharedTaskItem-date_wrapper-time': true,
              'SharedTaskItem-due': TaskType.Due == taskType,
              'SharedTaskItem-todo': TaskType.Todo == taskType,
              'SharedTaskItem-completed': TaskType.Completed == taskType,
            })}
          >
            {date_format}{' '}
            {task.dueDate != '' ? moment(task.dueDate).format('ll') : ''}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};
