import React, { useState } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { LOCAL_SHARED_TASKS_ENDPOINT } from '../../configs/endpoints';

const SharedWithMe = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();

  return (
    <Button
      icon={<i className="fas fa-share-alt"></i>}
      className="TodoTaskOptionsPanel-item"
      style={{
        width: '100%',
        padding: '20px 14px 20px 33px',
        fontSize: '14px',
        color: '#000000',
        marginTop: '15px',
        backgroundColor: '#e7eaef',
      }}
      onClick={() => {
        history.push(LOCAL_SHARED_TASKS_ENDPOINT);
      }}
    >
      Shared With Me
    </Button>
  );
};

export default SharedWithMe;
