import React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { getTaskById, TaskObj, TimeReminderObj } from '../../store/ducks/tasks';
import './TaskViewItem.scss';
import ConnectedTodoTaskProjectTag from '../todoTaskProjectTag/TodoTaskProjectTag';
import ConnectedTodoTaskLabelTag from '../todoTaskLabelTag/TodoTaskLabelTag';
import ConnectedTaskCompletionDateTag from '../taskCompletionDateTag/TaskCompletionDateTag';
import lodash from 'lodash';
import { Avatar, Dropdown, Tooltip } from 'antd';

import { getProjectById, ProjectObj } from '../../store/ducks/projects';
import ConnectedTodoAssigneeDropdownPicker from '../todoAssigneeDropdownPicker/TodoAssigneeDropdownPicker';
import {
  CollaboratorObj,
  getCollaborators,
} from '../../store/ducks/collaborators';
import TodoTaskActivityTag from '../../components/activities/todoTaskActivityTag/TodoTaskActivityTag';
import TaskReminderView from '../../components/reminders/taskReminderPicker/taskReminderView/TaskReminderView';
import moment from 'moment';
import { INTERNAL_DATE_TIME_FORMAT } from '../../configs/constants';
import Modal from 'antd/lib/modal/Modal';
import TaskShare from '../../components/todo/todoTaskOptionsPanel/TaskShare';

/** interface to describe TaskViewItem props */
interface TaskViewItemProps {
  taskId: string;
  taskInfo: TaskObj;
  projectInfo: ProjectObj;
  assignee: CollaboratorObj | null;
  onTaskAssigned: (assignedId: string) => void;
  setIsModalVisible?: any;
  isModalVisible?: boolean;
}

const TaskViewItem: React.FC<TaskViewItemProps> = (
  props: TaskViewItemProps
) => {
  const {
    taskInfo,
    assignee,
    projectInfo,
    onTaskAssigned,
    setIsModalVisible,
    isModalVisible,
  } = props;

  const [assigneePickerVisible, setAssigneePickerVisible] = React.useState<
    boolean
  >(false);

  const onAssigneePickerVisibleChange = (visible: boolean) => {
    setAssigneePickerVisible(visible);
  };

  const pendingReminders: TimeReminderObj[] = taskInfo.reminders || [];

  const stopBubbling = (event: React.MouseEvent) => {
    event?.stopPropagation();
  };
  const handleCancel = (e: any) => {
    e?.stopPropagation();
    setIsModalVisible(false);
  };

  const onTaskAssignedHandler = (assignedId: string) => {
    onTaskAssigned(assignedId);
    setAssigneePickerVisible(false);
  };

  return (
    <div className="TaskViewItem-container-wrapper">
      <div className="TaskViewItem-container">
        <div className="TaskViewItem-row">
          <div>{taskInfo.title}</div>
        </div>
        <div className="TaskViewItem-row">
          <div className="TaskViewItem-meta-row">
            <div className="TaskViewItem-project">
              <ConnectedTodoTaskProjectTag id={taskInfo.projectId} />
            </div>
            {taskInfo.labels.map((iterlabel: string, index: number) => (
              <ConnectedTodoTaskLabelTag
                id={iterlabel}
                key={taskInfo.id + '-label-' + index}
              />
            ))}
            {pendingReminders.length > 0 && taskInfo.dueDate !== '' && (
              <Tooltip
                placement="bottom"
                title={
                  <div className="TaskViewItem-reminders-tooltip">
                    <TaskReminderView
                      values={taskInfo.reminders}
                      scheduleDate={moment(
                        taskInfo.dueDate + ' ' + taskInfo.dueTime,
                        INTERNAL_DATE_TIME_FORMAT
                      )}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      changeHandler={() => {}}
                    />
                  </div>
                }
              >
                <div className="TaskViewItem-reminders">
                  <i className="fas fa-stopwatch" />
                  <span className="TaskViewItem-reminders-count">
                    {pendingReminders.length}
                  </span>
                </div>
              </Tooltip>
            )}
            {taskInfo.activityType !== '' && (
              <div className="TaskViewItem-activity-type">
                <TodoTaskActivityTag title={taskInfo.activityType} />
              </div>
            )}
            {taskInfo.commentsCount > 0 && (
              <Tooltip title="Comments" placement="bottom">
                <div className="TaskViewItem-comments">
                  <i className="fas fa-comment-alt" />
                  <span className="TaskViewItem-comments-count">
                    {taskInfo.commentsCount}
                  </span>
                </div>
              </Tooltip>
            )}
            {(taskInfo.isRecurring === 1 || taskInfo.referenceId) && (
              <Tooltip title="Recurring" placement="bottom">
                <div className="TaskViewItem-recurring">
                  <i className="fas fa-retweet" />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="TaskViewItem-date-container">
        <div className="TaskViewItem-due">
          <ConnectedTaskCompletionDateTag
            dueDate={taskInfo.dueDate}
            dueTime={taskInfo.dueTime}
          />
        </div>
        {projectInfo?.sharedWith?.length > 1 && (
          <div onClick={stopBubbling}>
            <Tooltip title="Assign" placement="bottom">
              <Dropdown
                overlay={
                  <ConnectedTodoAssigneeDropdownPicker
                    collaboratorIds={projectInfo?.sharedWith}
                    selected={taskInfo.assignedTo}
                    selectedHandler={onTaskAssignedHandler}
                  />
                }
                trigger={['click']}
                placement="bottomRight"
                visible={assigneePickerVisible}
                onVisibleChange={onAssigneePickerVisibleChange}
              >
                <div className="TaskViewItem-user-plus">
                  {assignee ? (
                    <Avatar size="small" src={assignee?.avatar}>
                      <div>{assignee?.name?.slice(0, 2)}</div>
                    </Avatar>
                  ) : (
                    <i className="fas fa-user-plus"></i>
                  )}
                </div>
              </Dropdown>
            </Tooltip>
          </div>
        )}

        {(taskInfo.sharedWith as Array<any>)?.length > 0 && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsModalVisible(true);
            }}
          >
            <Avatar.Group maxCount={3}>
              {(taskInfo.sharedWith as Array<any>)?.map(
                (iterCollaboratorId: any, iterCollaboratorIndex: number) => {
                  return (
                    <Avatar
                      size="small"
                      key={`collaborator-${iterCollaboratorIndex}`}
                      src={iterCollaboratorId?.avatar}
                    >
                      <div>{iterCollaboratorId?.name?.slice(0, 2)}</div>
                    </Avatar>
                  );
                }
              )}
            </Avatar.Group>
          </div>
        )}
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  taskInfo: TaskObj;
  projectInfo: ProjectObj;
  assignee: CollaboratorObj | null;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TaskViewItemProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const taskInfo = getTaskById(state, parentProps.taskId);
  const assignee =
    lodash.find(getCollaborators(state), { id: taskInfo?.assignedTo }) || null;
  const result = {
    taskInfo,
    assignee,
    projectInfo: getProjectById(state, taskInfo?.projectId) || {},
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TaskViewItem to the redux store */
const ConnectedTaskViewItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskViewItem);

export default ConnectedTaskViewItem;
